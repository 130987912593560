import React, { useContext } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { WrapperContext } from "../components/Layout";
import clsx from "clsx";
import SEO from "../components/seo";
import Slider from "../components/slices/Slider";
import ImageRandom from "../components/slices/ImageRandom";
import ImagesGridToSlider from "../components/slices/ImagesGridToSlider";
import Projets from "../components/slices/Projets";
import TexteImages from "../components/slices/TexteImages";
import { shuffle } from "../core/utils";

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        home_page
        title {
          text
        }
        texte {
          text
          raw
        }
        image {
          url
        }
        body {
          ... on PrismicPageBodyImageRandom {
            slice_type
            primary {
              size
            }
            items {
              image {
                ...sharpNoBase64
              }
            }
          }
          ... on PrismicPageBodySlider {
            slice_type
            primary {
              size
            }
            items {
              image {
                ...sharpNoBase64
              }
            }
          }
          ... on PrismicPageBodyImagesGrid {
            slice_type
            items {
              image {
                ...sharpNoBase64
              }
            }
          }
          ... on PrismicPageBodyProjets {
            slice_type
            items {
              item {
                document {
                  ... on PrismicProjet {
                    type
                    uid
                    data {
                      ...projet
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTexteImages {
            slice_type
            primary {
              chapo {
                raw
              }
              texte {
                raw
              }
            }
            items {
              image {
                ...sharpNoBase64
              }
            }
          }
        }
      }
    }
  }
`;

const TemplatePage = ({ data }) => {
  const { template } = useContext(WrapperContext);
  const { home_page, title, texte, image, body } = data.prismicPage.data;
  // console.log(body);
  // useEffect(() => {}, []);

  const slices = body.map((slice, i) => {
    // console.log(slice.slice_type);
    switch (slice.slice_type) {
      case "image_random":
        return (
          <ImageRandom
            key={i}
            size={slice.primary.size}
            input={shuffle(slice.items)}
          />
        );
      case "Slider":
        return (
          <Slider
            key={i}
            size={slice.primary.size}
            input={slice.items}
            autoplay={false}
          />
        );
      case "images_grid":
        return <ImagesGridToSlider key={i} input={slice.items} />;
      case "projets":
        return <Projets key={i} defaultDisplayType="all" input={slice.items} />;
      case "texte_images":
        return <TexteImages key={i} input={slice} />;
      default:
        return null;
    }
  });
  console.log(image);

  return (
    <div className="wrapper">
      <SEO
        pageTitle={title.text}
        pageDescription={texte.text}
        pageImage={image.url}
        template={`${template} ${home_page ? "no-scroll" : ""}`}
        page={!home_page}
      />
      {/* <h1>{template}</h1> */}
      <div className="slices">{slices}</div>
    </div>
  );
};

export default withPreview(TemplatePage);
// export default TemplatePage;
